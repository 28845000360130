import React, { useState } from 'react';
import { LaptopOutlined, NotificationOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Input, Button, Divider, List } from 'antd';

import './App.css';

const { Header, Content, Sider } = Layout;
const { TextArea } = Input;

const items1 = ['1', '2', '3'].map((key) => ({
  key,
  label: `nav ${key}`,
}));

const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map((icon, index) => {
  const key = String(index + 1);
  return {
    key: `sub${key}`,
    icon: React.createElement(icon),
    label: `subnav ${key}`,
    children: new Array(4).fill(null).map((_, j) => {
      const subKey = index * 4 + j + 1;
      return {
        key: subKey,
        label: `option${subKey}`,
      };
    }),
  };
});

const initData = [
  {
    title: '越秀',
    value_quezhen: 0,
    value_noSymptom: 0
  },
  {
    title: '海珠',
    value_quezhen: 0,
    value_noSymptom: 0
  },
  {
    title: '荔湾',
    value_quezhen: 0,
    value_noSymptom: 0
  },
  {
    title: '天河',
    value_quezhen: 0,
    value_noSymptom: 0
  },
  {
    title: '白云',
    value_quezhen: 0,
    value_noSymptom: 0
  },
  {
    title: '黄埔',
    value_quezhen: 0,
    value_noSymptom: 0
  },
  {
    title: '花都',
    value_quezhen: 0,
    value_noSymptom: 0
  },
  {
    title: '番禺',
    value_quezhen: 0,
    value_noSymptom: 0
  },
  {
    title: '南沙',
    value_quezhen: 0,
    value_noSymptom: 0
  },
  {
    title: '从化',
    value_quezhen: 0,
    value_noSymptom: 0
  },
  {
    title: '增城',
    value_quezhen: 0,
    value_noSymptom: 0
  },
  {
    title: '集中隔离场所隔离观察人数',
    value_quezhen: 0,
    value_noSymptom: 0
  }
];

const initAllData = [
  {
    title: '广州市新增本土无症状感染总人数',
    value: 0
  },
  {
    title: '广州市新增本土无症状感染总人数',
    value: 0
  }
]


const App = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');
  const [nowDate, setNowDate] = useState(''); // 公众号文章日期
  const [dataAll, setDataAll] = useState(initAllData); // 要输出的数据
  const [data, setData] = useState(initData); // 要输出的数据

  let queZhenA; // 各区确诊人数
  let jizhong_quezhenA; // 各区集中隔离观察人员中的确诊人数
  let noSymptomA;  // 各区无症状感染人数
  let jizhong_noSymptomA; // 各区无症状感染人数

  let allQueZhen = 0; // 广州市新增确诊总人数
  let allNoSymptom = 0; // 广州市新增无症状感染者总人数

  const {
    token: { colorBgContainer },
  } = theme.useToken();


  // 查看公众号文章的日期
  const findNowDate = () => {
    let start = value.indexOf('| ');
    let end = value.indexOf('广州市');
    console.log(value.slice(start + 1, end));
    setNowDate(value.slice(start + 1, end));
  }

  // 本土新增确诊人数
  const queZhen = () => {
    // 本土确诊病例
    let array1 = value.split('本土确诊病例').splice(3); // 去掉前三项
    array1 = array1.splice(0, array1.length - 2);

    // 冒号位置
    let array11 = array1.map(item => {
      // 打错冒号，将中文打成了英文
      if (item.indexOf('：') === -1) {
        return item.indexOf(':');
      } else {
        return item.indexOf('：');
      }
    })

    // 各数字
    let array111 = array1.map((item, index) => {
      return item.slice(0, array11[index]);
    })

    // 计算出各街道实际人数
    let array1111 = array111.map(item => {
      // 说明不能直接提取，需要做减法计算
      let _index = item.indexOf('-');
      let res;

      if (_index !== -1) {
        let num1 = item.slice(0, _index);
        let num2 = item.slice(_index + 1);
        res = num2 - num1 + 1;

      } else {
        res = 1;
      }
      return res;
    })

    // '区' 这个字的位置
    let array_qu_1 = array1.map(item => {
      return item.indexOf('区');
    })

    // 各区名字
    let array_qu_11 = array1.map((item, index) => {
      return item.slice(array_qu_1[index] - 2, array_qu_1[index]);
    })

    // 开始计算各区人数，即真实需要的输出结果
    let map = new Map(); // 计算各区的键值对
    array_qu_11.map((item, index) => {
      if (map.get(item)) {
        map.set(item, map.get(item) + array1111[index]);
      } else {
        map.set(item, array1111[index]);
      }
      allQueZhen += array1111[index];
    })

    console.log('--------本土确诊病例-------------------------------------------------------');
    console.log(array1);

    console.log('--------各街道人数-------------------------------------------------------');
    //console.log(array11);
    //console.log(array111);
    console.log(array1111);
    console.log('--------各区名-------------------------------------------------------');
    //console.log(array_qu_1);
    console.log(array_qu_11);
    console.log(map);

    queZhenA = map;
  }

  // 集中隔离场所确诊的人数
  const jizhong_quezhen = (array_quezhen) => {
    // 本土确诊病例
    let array1 = value.split('本土确诊病例').splice(3); // 去掉前三项
    array1 = array1.splice(0, array1.length - 1);

    // 冒号位置
    let array11 = array1[array1.length - 1].indexOf('：') === -1 ? array1[array1.length - 1].indexOf(':') : array1[array1.length - 1].indexOf('：');

    // 数字
    let array111 = array1[array1.length - 1].slice(0, array11);

    // 计算出各街道实际人数
    // 说明不能直接提取，需要做减法计算
    let _index = array111.indexOf('-');
    let res;
    if (_index !== -1) {
      let num1 = array111.slice(0, _index);
      let num2 = array111.slice(_index + 1);
      res = num2 - num1 + 1;
    } else {
      res = 1;
    }

    console.log('集中隔离场所中，本土确诊病例人数为：', res);

    jizhong_quezhenA = {
      title: '集中隔离场所隔离观察人数',
      value: res
    }
    allQueZhen += res;
  }


  // 本土新增无症状感染人数
  const noSymptom = () => {
    // 本土无症状感染者病例
    let array1 = value.split('本土无症状感染者').splice(4); // 去掉前四项
    array1 = array1.splice(0, array1.length - 3);

    // 冒号位置
    let array11 = array1.map(item => {
      // 打错冒号，将中文打成了英文
      if (item.indexOf('：') === -1) {
        return item.indexOf(':');
      } else {
        return item.indexOf('：');
      }
    })

    // 各数字
    let array111 = array1.map((item, index) => {
      return item.slice(0, array11[index]);
    })

    // 计算出各街道实际人数
    let array1111 = array111.map(item => {
      // 说明不能直接提取，需要做减法计算
      let _index = item.indexOf('-');
      let res;

      if (_index !== -1) {
        let num1 = item.slice(0, _index);
        let num2 = item.slice(_index + 1);
        res = num2 - num1 + 1;

      } else {
        res = 1;
      }
      return res;
    })

    // '区' 这个字的位置
    let array_qu_1 = array1.map(item => {
      return item.indexOf('区');
    })

    // 各区名字
    let array_qu_11 = array1.map((item, index) => {
      return item.slice(array_qu_1[index] - 2, array_qu_1[index]);
    })

    // 开始计算各区人数，即真实需要的输出结果
    let map = new Map(); // 计算各区的键值对
    array_qu_11.map((item, index) => {
      if (map.get(item)) {
        map.set(item, map.get(item) + array1111[index]);
      } else {
        map.set(item, array1111[index]);
      }
      allNoSymptom += array1111[index];
    })

    console.log('--------本土无症状感染者-------------------------------------------------------');
    console.log(array1);

    console.log('--------各街道人数-------------------------------------------------------');
    //console.log(array11);
    //console.log(array111);
    console.log(array1111);
    console.log('--------各区名-------------------------------------------------------');
    //console.log(array_qu_1);
    console.log(array_qu_11);
    console.log(map);

    noSymptomA = map;
  }

  // 集中隔离场所确诊的人数
  const jizhong_noSymptom = (array_quezhen) => {
    // 本土无症状感染者病例
    let array1 = value.split('本土无症状感染者').splice(4); // 去掉前四项
    array1 = array1.splice(0, array1.length - 2);

    // 冒号位置
    let array11 = array1[array1.length - 1].indexOf('：') === -1 ? array1[array1.length - 1].indexOf(':') : array1[array1.length - 1].indexOf('：');

    // 数字
    let array111 = array1[array1.length - 1].slice(0, array11);

    // 计算出各街道实际人数
    // 说明不能直接提取，需要做减法计算
    let _index = array111.indexOf('-');
    let res;
    if (_index !== -1) {
      let num1 = array111.slice(0, _index);
      let num2 = array111.slice(_index + 1);
      res = num2 - num1 + 1;
    } else {
      res = 1;
    }

    console.log('集中隔离场所中，本土无症状感染人数为：', res);

    jizhong_noSymptomA = {
      title: '集中隔离场所隔离观察人数',
      value: res
    }
    allNoSymptom += res;
  }

  const search = () => {
    //console.log(value);
    setLoading(true);

    findNowDate(); // 查看公众号文章的日期
    queZhen(); // 计算本土新增确诊人数
    jizhong_quezhen(); // 计算集中隔离场所确诊的人数
    noSymptom() // 计算本土新增无症状感染人数
    jizhong_noSymptom(); // 计算集中隔离场所无症状的人数

    //setData([...queZhenA, ...jizhong_quezhenA, ...noSymptomA, ...jizhong_noSymptomA]);
    let arrayAll = [];
    let array = [];

    // 整体
    arrayAll.push({
      title: '广州市新增本土确诊总人数',
      value: allQueZhen
    })

    arrayAll.push({
      title: '广州市新增本土无症状感染总人数',
      value: allNoSymptom
    })

    // 具体到各区
    queZhenA.forEach((val, key) => {
      array.push({
        title: key,
        value_quezhen: val,
        value_noSymptom: noSymptomA.get(key)
      })
    });

    array.push({
      title: '集中隔离场所隔离观察人数',
      value_quezhen: jizhong_quezhenA.value,
      value_noSymptom: jizhong_noSymptomA.value
    })

    console.log(arrayAll);
    console.log(array);
    setDataAll([...arrayAll])
    setData([...array])

    setLoading(false);
  }


  return (
    <Layout className="app">
      <Header className="header">
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} items={items1} />
      </Header>
      <Layout>
        <Sider
          width={200}
          style={{
            background: colorBgContainer,
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{
              height: '100%',
              borderRight: 0,
            }}
            items={items2}
          />
        </Sider>
        <Layout
          style={{
            padding: '0 24px 24px',
          }}
        >
          <Breadcrumb
            style={{
              margin: '16px 0',
            }}
          >
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item>
          </Breadcrumb>
          <Content
            style={{
              padding: 20,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
            }}
          >
            <TextArea
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="请输入今日要查询的公众号文章（文本形式）"
              autoSize={{ minRows: 3, maxRows: 3 }}
            />
            <div style={{ margin: '24px 0' }} />
            <Button type="primary" icon={<SearchOutlined />} loading={loading} onClick={() => search()}>
              Search
            </Button>
            <>
              <Divider orientation="middle">{nowDate} 广州市各区疫情新增情况</Divider>

              {/* 总体 */}
              <List
                size="large"
                bordered
                dataSource={dataAll}
                renderItem={(item, index) =>
                  <List.Item>
                    {item.title}
                    ：共新增 <div className="text">{item.value}</div> 例
                  </List.Item>}
              />

              <div style={{ margin: '24px 0' }} />

              {/* 各区 */}
              <List
                size="large"
                bordered
                dataSource={data}
                renderItem={(item, index) =>
                  <List.Item>
                    {item.title}
                    <div style={{ display: index === data.length - 1 ? "none" : "inline-block" }}>区</div>
                    ：共新增 <div className="text">{item.value_quezhen + item.value_noSymptom}</div> 例，
                    其中确诊 <div className="text">{item.value_quezhen}</div> 例，
                    无症状感染者 <div className="text">{item.value_noSymptom}</div> 例
                  </List.Item>}
              />

            </>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default App;